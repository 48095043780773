














































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import CreateWorkspaceInviteModal from "@/components/settings/workspace/CreateWorkspaceInviteModal.vue";
import UserAvatar from "@/components/user/UserAvatar.vue";
import ConfettiCanon from "@/components/general/ConfettiCanon.vue";
import BoardMembers from "@/components/board/BoardMembers.vue";
import UserProfile from "@/components/user/profile/UserProfile.vue";


interface ChecklistItem {
  id: number;
  title: string;
  requiredCount?: number;
  currentCount?: number;
  isChecked: boolean;
  xpClaimed: boolean; 
  xpAmount: number;
  iconSize?: string;
  isAction?: boolean;
  action?: () => void;
  animate?: boolean;
  clickEvent?: () => void;
  pulse?: boolean;
}

@Component({
  components: {
    CreateWorkspaceInviteModal, UserAvatar, ConfettiCanon, UserProfile
  },
})
export default class OnboardingChecklistButton extends Vue {
  checklistItems: ChecklistItem[] = [];
  userXP: number = 0;
  fadeItem: boolean = false;
  fireConfetti: boolean = false;
  listCompleted: boolean = false;

  @Prop({default: false, required: false})
  manualOpen!: boolean

  mounted() {
    setTimeout(() => {
      this.initializeChecklist();
    }, 2000);
  }

  get me() {
    return this.$store.state.me;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get singleMemberWorkspace() {
    return this.workspace.members.length === 1;
  }

  get singleRoomWorkspace() {
    return this.workspace.boards.length === 1;
  }

  get inviteSent() {
    return this.$store.state.invite_sent
  }

  get userBrowsedTemplates() {
    return this.$store.state.user_browsed_templates
  }

  
  get sidebarOpened() {
    return this.$store.state.sidebar_opened
  }

  get activitesCreated() {
    return this.workspace.totalObjectivesCount;
  }

  get addedNorthStarCount() {
    return this.$store.state.board?.northStars?.length || 0;
  }

  get onboarding() {
    return this.$store.state.expanded_onboarding;
  }

  get onGuestAccount() {
    return this.$store.state.me.isFake;
  }


  // get checkedItems(): ChecklistItem[] {
  //   return this.checklistItems.filter(item => item.isChecked);
  // }

  get displayedItems(): ChecklistItem[] {
    const completeIds = this.getCompleteTaskIds;
    return this.manualOpen
      ? this.checklistItems
      : this.checklistItems.filter(item => completeIds.includes(item.id));
  }

  get getCompleteTaskIds() {
    return this.$store.state.completed_checklist_items;
  }

  get allItemsCompleted(): boolean {
    return this.checklistItems.every(item => item.isChecked);
  }

  get percentageCompleted(): number {
    if (this.checklistItems.length === 0) return 0;
    const completed = this.checklistItems.filter(item => item.isChecked).length;
    return Math.floor((completed / this.checklistItems.length) * 100);
  }

  initializeChecklist() {
    this.checklistItems = [
      {
        id: 1,
        title: 'Create 2 activities',
        requiredCount: 2,
        currentCount: this.activitesCreated,
        isChecked: this.activitesCreated >= 2,
        xpClaimed: false, 
        xpAmount: 3,
        iconSize: 'is-19x19',
      },
      {
        id: 3,
        title: 'Create 7 activities',
        requiredCount: 7,
        currentCount: this.activitesCreated,
        isChecked: this.activitesCreated >= 7,
        xpClaimed: false, 
        xpAmount: 7,
        iconSize: 'is-20x20',
        animate: this.activitesCreated === 7,
        pulse: this.activitesCreated >= 3,
        clickEvent: this.handleActivityClick,
      },
      {
        id: 4,
        title: 'Create 2 goals',
        requiredCount: 2,
        currentCount: this.addedNorthStarCount,
        isChecked: this.addedNorthStarCount >= 2,
        xpClaimed: false, 
        xpAmount: 3,
        pulse: this.activitesCreated >= 7,
        iconSize: 'is-20x20',
        clickEvent: this.handleGoalClick,
      },
      {
        id: 5,
        title: 'Browse templates',
        isChecked: this.userBrowsedTemplates,
        xpClaimed: false,
        xpAmount: 10,
        isAction: true,
        pulse: this.addedNorthStarCount >= 2 && this.activitesCreated >= 7 ,
        clickEvent: this.handleTemplateClick,
        iconSize: 'is-20x20',
      },
      {
        id: 6,
        title: 'Explore the overview',
        isChecked: this.sidebarOpened,
        xpClaimed: false,
        xpAmount: 10,
        isAction: true,
        pulse: this.addedNorthStarCount >= 2 && this.activitesCreated >= 7 && this.userBrowsedTemplates,
        clickEvent: this.handleSidebarClick,
        iconSize: 'is-20x20',
      },
      // {
      //   id: 4,
      //   title: 'Complete 3 activites',
      //   requiredCount: 3,
      //   currentCount: this.addedNorthStarCount,
      //   isChecked: this.addedNorthStarCount >= 3,
      //   xpClaimed: false, // Initialize as not claimed
      //   xpAmount: 6,
      //   iconSize: 'is-20x20',
      //   clickEvent: this.handleActivityClick,
      // },
      {
        id: 7,
        title: 'Invite collaborator',
        isChecked: this.inviteSent,
        xpClaimed: false,
        xpAmount: 10,
        isAction: true,
        pulse: this.addedNorthStarCount >= 2 && this.activitesCreated >= 7 && this.userBrowsedTemplates && this.sidebarOpened && !this.inviteSent ,
        action: this.openBoardMembers,
        iconSize: 'is-20x20',
      },
 
      // {
      //   id: 5,
      //   title: 'Add a room',
      //   isChecked: !this.singleRoomWorkspace,
      //   xpClaimed: false, // Initialize as not claimed
      //   xpAmount: 10,
      //   isAction: true,
      //   action: this.openRoomAddModal, // Define this method if needed
      //   iconSize: 'is-20x20',
      // },
    ];
    
    // Automatically assign XP for already completed items on initialization
    this.checklistItems.forEach(item => {
      if (item.isChecked && !item.xpClaimed) {
        this.assignXP(item);
      }
    });
  }



  @Watch('activitesCreated')
  onActivitesCreatedChange(newVal: number) {

    if(newVal === 5) {
      this.$store.commit('set_show_checklist_prompt', false);
      this.$store.commit('set_show_activity_progress', true);
    }
    
    // updateChecklistItem(which item to update, condition to check off item, new value to compare with)
    this.updateChecklistItem(1, newVal >= 3, newVal);
    this.updateChecklistItem(2, newVal >= 5, newVal);
    this.updateChecklistItem(3, newVal >= 7, newVal);
  }

  @Watch('addedNorthStarCount')
  onAddedNorthStarCountChange(newVal: number) {
    this.updateChecklistItem(4, newVal >= 2, newVal);
  }

  @Watch('userBrowsedTemplates')
  onUserBrowsedTemplates(newVal: boolean) {
    this.updateChecklistItem(5, newVal);
  }

  @Watch('sidebarOpened')
  onSidebarOpened(newVal: boolean) {
    this.updateChecklistItem(6, newVal);
  }

  @Watch('inviteSent')
  oninviteSent(newVal: boolean) {
    this.updateChecklistItem(7, newVal);
  }


  @Watch('allItemsCompleted')
  onAllItemsCompletedChange(newVal: boolean) {
    if (newVal === true) {
      this.$store.commit('set_expanded_onboarding', 3);
      this.handleChecklistVisibility(false);
      this.listCompleted = true;
    }
  }

  openFeedback() {
    this.$buefy.modal.open({
      component: UserProfile,
      // @ts-ignore
      customClass: 'is-paddingless user_profile_modal',
      props: {
        redirect: 'feedback'
      },
      width: '700px',
      parent: this,
    });
  }


  updateChecklistItem(id: number, isChecked: boolean, currentCount?: number) {
    const item = this.checklistItems.find(item => item.id === id);
    if (item) {
      const wasChecked = item.isChecked;
      item.isChecked = isChecked;
      if (currentCount !== undefined) {
        item.currentCount = currentCount;
      }
      if (isChecked && !item.xpClaimed) {
        this.assignXP(item);
        //Dont show notification for first list item

        this.$store.commit('clear_completed_checklist_items');
        this.$store.commit('add_completed_checklist_item', id);
        this.handleChecklistVisibility(false);
        this.initializeChecklist();
        this.triggerConfetti();
        
      }
      if (id === 2 && isChecked && !wasChecked) {
        item.animate = true;
        setTimeout(() => { item.animate = false; }, 1000); 
      }
      
    }
  }


  triggerConfetti() {
    setTimeout(() => {
      this.fireConfetti = true; 
      setTimeout(() => {
        this.fireConfetti = false; 
      }, 2000);
    }, 1000); 
  }

  assignXP(item: ChecklistItem) {
    this.userXP += item.xpAmount;
    item.xpClaimed = true;

    // this.$buefy.toast.open({
    //   message: `You received ${item.xpAmount} points!`,
    //   type: 'is-success',
    //   duration: 3000,
    // });
  }

  handleGoalClick() {
    this.$store.commit('set_create_goal_tooltip', true);
    this.$store.commit('set_explore_sidebar_tooltip', false);
    this.$store.commit('set_add_objective_tooltip', false);
    this.$store.commit('set_create_template_tooltip', false);
    this.$store.commit('clear_completed_checklist_items');
    this.$gtag.event('pmb_user_clicked_on_add_goal_checklist_item');
    this.handleChecklistVisibility(true)
  }

  handleTemplateClick() {
    this.$store.commit('set_create_template_tooltip', true);
    this.$store.commit('set_explore_sidebar_tooltip', false);
    this.$store.commit('set_create_goal_tooltip', false);
    this.$store.commit('set_add_objective_tooltip', false);
    this.$store.commit('clear_completed_checklist_items');
    this.$gtag.event('pmb_user_clicked_on_browse_template_checklist_item');
    this.handleChecklistVisibility(true)
  }

  handleSidebarClick() {
    this.$store.commit('set_explore_sidebar_tooltip', true);
    this.$store.commit('set_create_template_tooltip', false);
    this.$store.commit('set_create_goal_tooltip', false);
    this.$store.commit('set_add_objective_tooltip', false);
    this.$store.commit('clear_completed_checklist_items');
    this.$gtag.event('pmb_user_clicked_on_explore_sidebar_item');
    this.handleChecklistVisibility(true)
  }
 
  handleActivityClick() {
    this.$store.commit('set_add_objective_tooltip', true);
    this.$store.commit('set_explore_sidebar_tooltip', false);
    this.$store.commit('set_create_goal_tooltip', false);
    this.$store.commit('clear_completed_checklist_items');
    this.$gtag.event('pmb_user_clicked_on_add_goal_checklist_item');
    this.$gtag.event('pmb_user_clicked_on_add_activity_checklist_item');
    this.handleChecklistVisibility(true)
  }

  handleChecklistVisibility(manual: boolean) {
    this.$emit('handle-checklist-click', manual)
  }

  handleNotificationClick() {
    if(!this.manualOpen) {
      this.$store.commit('clear_completed_checklist_items');
      this.$emit('switch-to-full-view')
      if(this.onboarding === 3) {
        this.$store.commit('set_expanded_onboarding', null);
      }

    } else { return }
  }

  openBoardMembers() {
    this.$gtag.event('pmb_user_clicked_on_invite_collaborator_checklist_item');
    this.handleChecklistVisibility(true);
    this.$buefy.modal.open({
      component: BoardMembers,
      width: '480px',
      parent: this,
      props: {
        fromMemberInviteClick: true
      }
    }); 
  }

}
